.arrowBack {
  font-weight: bold;
  color: #29272E;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: #29272E;
    opacity: 0.8;
  }
}
