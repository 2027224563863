.content {
  .box {
    .boxHeader {
      display: flex;
      flex-direction: column;

      .filter {
        background-color: #fff;
        margin-bottom: 10px;
        padding: 20px;

        .colBtn {
          display: flex;
          justify-content: flex-end;

          .filterBtn {
            background: transparent;
            border: 2px solid #EDC947;
            border-radius: 9px;
            height: 46px;
            width: 129px;
            color: #4f5d73;
            font-weight: 600;
          }

          .filterBtn:hover {
            background: #FDE796;
            transition: 2s;
          }

        }

        .rowFilter {
          margin-top: 5px;
        }

      }

      .options {

      }
    }

    .listing {
      .listingContent {
        display: flex;
        flex-direction: column;

        .listingItem {
          //cursor: pointer;
          text-decoration: none;
          color: #6a6c6f;
          min-height: 50px;

          &:hover {
            opacity: 0.8;
          }

          .listingItem1 {
            .date {
              font-size: 12px;
              font-weight: 500;
              width: 85px;
              overflow: hidden;
            }

            a {
              text-decoration: none;
              color: #6a6c6f;
            }
          }

          .listingItem2 {
            background-color: #fff;
            padding: 10px 15px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            gap: 10px;
            min-height: 68px;
            @media(max-width: 769px) {
              flex-direction: column;
              align-items: flex-start;
            }

            .titleName {
              text-transform: capitalize;
              width: 80%;
              word-wrap: break-word;
              word-break: break-all;
            }

            .title {
              width: 80%;
              word-wrap: break-word;
              word-break: break-all;
            }

            .options {
              display: flex;
              gap: 10px;
              align-items: center;

              span {
                text-transform: capitalize;
              }
            }

            .colBtn {
              display: flex;
              justify-content: flex-end;

              .access {
                width: 190px;
                max-width: 190px;
                background: #FDE796;
                border-radius: 8px;
                font-weight: 600;
                margin: 0 auto;
                height: 50px;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 13px;

                .btnAccess {
                  font-weight: 600;
                  color: #4f5d73;
                  text-decoration: none;
                  height: 100%;
                  width: 100%;
                  text-align: center;
                }
              }
            }

          }
        }

        .titleLimit {
          word-wrap: break-word;
          word-break: break-all;
        }
      }
    }

    .totalItems {
      display: flex;
      gap: 20px;
      margin-bottom: 10px;
      background: white;
      padding: 20px;

      .textDecoration {
        text-transform: capitalize;
        margin: 0;
      }
    }

    .table {
      display: flex;
      flex-direction: column;
      @media(max-width: 769px) {
        overflow: scroll;
        width: 330px;
      }

      .filterBtn {
        background: #FDE796;
        border: none;
        border-radius: 8px;
        height: 46px;
        width: 129px;
        color: #4f5d73;
        font-weight: 600;
        margin-right: 3px;
      }

      .export {
        display: flex;
        justify-content: end;
        margin-right: 10px;
        border:none;
      }

      p {
        margin: 0;
      }

      table {
        background-color: #ffff;

        thead {
          background-color: #303c4c;
          color: #ffffff;
        }

        tbody {
          tr {
            border-bottom: #c4c5c7 1px solid;
            color: #303c4c;

            .editButton {
              color: #4F5D73;
              background: #FDE796;
              border: 2px solid #FDE796;
              border-radius: 9px;
            }

            .deleteButton {
              color: #FFFFFF;
              background: #EF4848;
              border: #EF4848;
              border-radius: 9px;
            }
          }
        }
      }
    }
  }
}
