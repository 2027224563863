.header {
  background-color: #303c4c;
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: center;

  .align {
    max-width: 1111px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    @media(max-width: 1111px){
      padding: 10px;
    }
  }

  span {
    color: #ffffff;
    display: flex;
    align-items: center;

    img{
      padding-right: 5px;
    }

    strong{
      padding-left: 3px;
    }
  }
}
