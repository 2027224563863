.modalContent {
  height: 222px;
  border-radius: 1.25rem;
  padding: 0 1.25rem;
  margin-top: 80px;

  strong {
    text-align: center;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.688rem;

    padding: 10px 15px 0;
    color: var(--grey-text-dark);
  }

  .close {
    display: flex;
    justify-content: flex-end;

    button{
      margin: 10px;
      border: #6a6c6f 1px solid;
      border-radius: 20px;
      background-color: #ffffff;
      height: 25px;
      width: 25px;
    }

  }

  p {
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.688rem;


    color: var(--greyTextLight);
    margin: 1.25rem 0 2.625rem 0;
  }

  .spinner {
    display: flex;
    justify-content: center;
    margin: 26px
  }

  .loading {
    color: var(--grey-text-dark);
    border-width: 2px;
    margin: 25px;
  }

  .inputCards{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 25px;
  }
  .inputButtons{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 25px;

    Button:nth-child(1) {
      background: rgba(0,0,0,0.42);
      color: #FFFFFF;
      border: 2px solid rgba(0,0,0,0.13);
      border-radius: 9px;
    }

    Button:nth-child(2) {
      background: #4AAE8C;
      color: #FFFFFF;
      border: 2px solid #4AAE8C;
      border-radius: 9px;
    }

    .buttonDisable{
      cursor: wait;
    }
  }
}
