.header {
  display: flex;
  height: 60px;
  background-color: #303c4c;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 150;

  img {
    cursor: pointer;
  }

  .alignHeader {
    display: flex;

    .title {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: space-around;
      width: 180px;
      border-color: currentcolor rgba(0, 0, 21, 0.2) rgba(0, 0, 21, 0.2);
      border-right: 1px solid rgba(0, 0, 21, 0.2);
      flex-wrap: wrap;
      color: #444444;
      font-family: "Roboto", sans-serif;
      font-size: 14px;

      p {
        color: #ffffff;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 0;
      }
    }

    .sideBarButton {
      width: 60px;
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: space-around;
      border-color: currentcolor rgba(0, 0, 21, 0.2) rgba(0, 0, 21, 0.2);
      border-right: 1px solid rgba(0, 0, 21, 0.2);

      img {
        max-width: 20px;
      }
    }
  }


  .profile {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: 30px;

    img {
      max-width: 35px;
    }
  }

}


.menu {
  position: fixed;
  background-color: #303c4c;
  width: 180px;
  bottom: 0;
  float: left;
  left: 0;
  height: 100vh;
  top: 60px;

  margin-left: 0;
  transition: all 0.25s;

  .info {
    padding: 20px 15px;
  }

  &.sideBar {
    margin-left: 0;
    z-index: 99;
    transition: all 0.25s;
    height: 100%;
  }

  &.noSideBar {
    transition: all 0.25s;
    margin-left: -299px;
  }

  .cards {
    align-items: center;
    display: flex;
    flex: 1 1;
    padding: 0.8445rem 1rem;
    gap: 10px;
    border-color: currentcolor rgba(0, 0, 21, .2) rgba(0, 0, 21, .2);
    border-bottom: 1px solid rgba(0, 0, 21, .2);
    text-decoration: none;

    p {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      color: #ffffff;
      text-decoration: none;
      margin: 0;
    }

    img {
      max-width: 20px;
    }
  }

  .cardsDrawer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-color: currentcolor rgba(0, 0, 21, .2) rgba(0, 0, 21, .2);
    border-bottom: 1px solid rgba(0, 0, 21, .2);
    text-decoration: none;

    p {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      color: #ffffff;
      text-decoration: none;
      margin: 0;
      display: flex;
      align-items: center;

      img {
        margin: 0 0 0 40px;
        max-width: 6px;
      }

      .arrow {
        width: 20px;
        height: 20px;
      }

      .rotate {
        transform: rotate(90deg);
        animation: rotateAnimation 0.5s ease;
      }

      .rotateBack {
        transform: rotate(0deg);
        animation: rotateBackAnimation 0.5s ease;
      }

      @keyframes rotateAnimation {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(90deg);
        }
      }

      @keyframes rotateBackAnimation {
        from {
          transform: rotate(90deg);
        }
        to {
          transform: rotate(0deg);
        }
      }
    }

    .cards {
      border: 0;
      background: none;

      .alignMenu {
        display: flex;
        gap: 15px;
      }
    }

    @keyframes show {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .options {
      width: 100%;
      animation: show 1s;

      .drawer {
        align-items: center;
        display: flex;
        padding: 0.65rem 0 0.55rem 1rem;
        border-color: currentcolor rgba(0, 0, 21, 0.2) rgba(0, 0, 21, 0.2);
        text-decoration: none;
        border-top: 1px solid rgba(0, 0, 21, .2);
        justify-content: flex-start;

        p {
          font-size: 13px;
          font-weight: 500;
        }
      }

      .drawer:hover {
        cursor: pointer;
        background: #283241;
      }
    }
  }

  .cards:hover {
    cursor: pointer;
    background: #283241;
  }
}


.mainContainer {
  margin: 0 0 0 180px;

  background: #f1f3f6;
  border-left: 1px solid #e4e5e7;
  transition: all 0.4s ease 0s;
  position: relative;
  min-height: 100%;
  height: 100vh;
  padding: 100px 40px 40px 40px;
  min-width: 320px;

  &.noSideBar {
    margin-left: 0;
  }

  @media(max-width: 1150px) {
    display: flex;
    justify-content: center;
    padding: 115px 0;
    margin-bottom: 100px;
  }
}



