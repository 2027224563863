.content {
  .box {
    display: grid;
    grid-template-columns: 0fr 0fr 0fr;
    gap: 75px;
    justify-content: center;
    @media(max-width: 1600px) {
      grid-template-columns: 0.8fr 0fr;
    }
    @media(max-width: 1136px) {
      display: flex;
      flex-direction: column;
    }

    .card {
      display: flex;
      flex-direction: column;

      .resolved {
        width: 105px;
        @media(max-width: 769px) {
          width: 80px;
        }
      }

      .pending {
        width: 110px;
        @media(max-width: 769px) {
          width: 80px;
        }
      }

      .answered {
        width: 78px;
        margin: 0 12px 0 6px;
        @media(max-width: 769px) {
          width: 60px;
        }
      }

      .cardBox {
        display: flex;
        align-items: center;
        padding: 0px 0px 0px 18px;
        width: 400px;
        background: #fff;
        height: 130px;
        border-radius: 18px;
        box-shadow: 0 0 13px 1px #d5d5d5;
        cursor: pointer;
        @media(max-width: 769px) {
          width: 100%;
        }
      }

      .number {
        font-size: 46px;
        margin: 0;
        @media(max-width: 769px) {
          font-size: 32px;
        }

        span {
          font-size: 17px;
          margin-left: 2px;
          @media(max-width: 769px) {
            font-size: 13px;
          }
        }
      }
    }
  }
}
