.quillItem {
  background-color: #fff;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  padding: 10px;

  .answerNames {
    color: #666666;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  button {
    width: 100px;
    place-self: flex-end;

  }

  .anotation {
    &.readonly {
      min-height: 10px;

      > div {
        &:first-child {
          display: none;
        }
      }
    }

    > div {
      &:first-child:after {
        display: none;
      }
    }

    min-height: 390px;
    height: auto;

    > div + div {
      border: none;
      font-size:14px;

      > div {
        &:first-child {
          min-height: 320px;
        }
      }
    }
  }
}

.answerItemWrapper {
  margin: 20px auto;

  .answerItem {
    border: 1px solid #4AAE8C;
    height: auto;
    background-color: #ffffff;
    border-radius: 5px;
    padding:10px;
    box-shadow: 0 1px 6px 1px #00000014;
    word-break: break-all;
    img {max-width: 100%}
  }

  .answerNames {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    span {
      color: #666666;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 4px;

      &.checkNaoResolvido {
        font-weight: 500;
        cursor: pointer;
      }

      &.checkResolvido {
        font-weight: 500;
        color:#4AAE8C;
        cursor: inherit;
      }

      &.checkWait {
        opacity: 0.5;
        cursor: wait;
      }
    }
  }
}
