.login {
  min-height: 100vh;
  text-align: center;
  padding-top:30px;
  background-color: #f7f7f7;

  h1 {
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    margin-top:0px;
  }

  span {
    color: #666666;
    font-weight: 400;
    font-size: 14px;
  }

  .socials {
    padding: 20px 0;

    button {
      border: none;
      background: none;
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    form {
      margin-top: 12px;
      display: flex;
      width: 350px;

      @media(max-width: 769px) {
        width: 95%;
      }

      .boxLogin {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        width: 100%;
        height: auto;
        border-radius: 5px;
        padding: 20px 20px 20px 20px;
        margin-top:80px;

        h1 {
          margin: 0;
          width: 313px;
          font-size: 22px;
          font-weight: normal;
          line-height: 1.5;
          text-align: center;
          color: #4a4a4a;
          margin-bottom: 29px;
        }

        p.error {
          color: red;
          font-size: 10px;
          padding: 7px;
          margin: 0;
        }


        p {
          margin-top: 20px;
          margin-bottom: 8px;
          font-weight: 500;
          font-size: 14px;
          color: #425466;
          text-align: left;

          &.error {
            color: red;
            font-size: 10px;
            padding: 7px;
            margin: 0;
          }
        }

        input {
          &[type=text], &[type=password] {
            height: 36px;
            width: 97%;
            border: none;
            border-radius: 6px;
            font-weight: 500;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
            padding-left: 9px;
            font-size: 13px;
          }

          &:focus {
            outline: 0;
          }
        }

        label {
          &.customCheckbox {
            margin-top:10px;
            display: flex;
            align-items: center;

            input {
              cursor: pointer;
            }

            span {
              text-decoration: none;
              color: #29272E;
              font-weight: 400;
              font-size: 12px;
              padding-left: 5px;

              a {
                text-decoration: underline;
                color: #29272E;
              }
            }
          }
        }

        .formGroup {
          display: flex;
          flex-direction: column;
          margin-top: 31px;
          margin-bottom: 12px;
          align-items: center;

          button {
            width: 131px;
            height: 46px;
            border-radius: 8px;
            border: none;
            cursor: pointer;
            background-color: #FCD64B;
            font-size: 14px;
            font-weight: 500;
            color: #666666;
          }

          button:hover {
            background: #ffe68a;
            transition: all 0.3s;
          }
        }

        .inputGroup {
          position: relative;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          text-align: left;
          width: 100%;

          img {
            position: absolute;
            right: 20px;
            top: 60px;
            cursor: pointer;
          }
        }
      }
    }
    .group {
      display: flex;
      flex-direction: column;
      align-items: center;

      button.remember {
        border: none;
        background: none;
        color: #666666;
        font-weight: 400;
        font-size: 12px;
        text-decoration: underline;
        cursor: pointer;
        margin-bottom: 15px;
      }
    }
  }
  .outsideFormGroup {
    display: flex;
    flex-direction: column;
    margin-top: 31px;
    align-items: center;
    margin-bottom: 20px;

    a {
      width: 131px;
      height: 46px;
      border-radius: 8px;
      background-color: transparent;
      cursor: pointer;
      border: 1px solid #fcd64b;
      font-size: 14px;
      font-weight: 500;
      color: #666666;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: #ffe68a;
        transition: all 0.3s;
      }
    }
  }
}
