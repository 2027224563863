.modalContent {
  height: 222px;
  border-radius: 1.25rem;
  padding: 0 1.25rem;
  margin-top: 80px;

  strong {
    text-align: center;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.688rem;

    padding: 10px 15px 0;
    color: var(--grey-text-dark);
  }

  .close {
    display: flex;
    justify-content: flex-end;

    button{
      margin: 10px;
      border: #6a6c6f 1px solid;
      border-radius: 20px;
      background-color: #ffffff;
      height: 25px;
      width: 25px;
    }

  }
  .description{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  p {
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.688rem;
    color: var(--greyTextLight);
    margin: 0 0 2.625rem 0;
  }


  .inputButtons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 25px;

    Button:nth-child(1) {
      background: rgba(0,0,0,0.42);
      color: #FFFFFF;
      border: 2px solid rgba(0,0,0,0.13);
      border-radius: 9px;
    }

    Button:nth-child(2) {
      border-radius: 9px;
    }

    .success {
      color: #fff;
      background-color: #28a745;
      border-color: #28a745;
    }
    .danger {
      background: #EF4848;
      color: #FFFFFF;
      border: 2px solid #EF4848;
    }
    .default {
      background: #EF4848;
      color: #FFFFFF;
      border: 2px solid #EF4848;
    }

    .buttonDisable{
      cursor: wait;
    }
  }
}
