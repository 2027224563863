.content {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .box {
    height: 90px;
    background-color: #fff;
    border-radius: 8px;


    @media(max-width: 1155px) {
      width: 100%;
      height: auto;
    }

    @media(max-width: 769px) {
      max-width: 400px;
      margin: 0 auto;
    }

    .filter {
      display: flex;
      justify-content: space-between;
      @media(max-width: 769px) {
        align-items: center;
        flex-direction: column;
        padding-bottom: 10px;
      }

      .inputText {
        display: flex;
        @media(max-width: 1155px) {
          flex-direction: column;
          align-items: center;
        }
      }

      .buttonFilter {
        margin: 25px 30px 0 0;
        @media(max-width: 769px) {
          margin: 25px 0;
        }

        Button {
          color: #4a4a4a;
          background: transparent;
          border: 2px solid #EDC947;
          border-radius: 9px;
          height: 46px;
          width: 129px;
        }

        Button:hover {
          background: #FDE796;
          transition: 2s;
        }

        .buttonDisable {
          background-color: #666666;
          cursor: wait;
        }
      }

      Input {
        width: 300px;
        margin: 25px 0 0 25px;

        @media(max-width: 769px){
          margin: 25px 0 0 0;
        }
      }
    }
  }

  .add {
    @media(max-width: 769px) {
      text-align: center;
    }

    Button {
      background: #4AAE8C;
      color: #FFFFFF;
      border: 2px solid #4AAE8C;
      border-radius: 9px;
      height: 46px;
      width: 129px;
    }

    .buttonDisable {
      background-color: #666666;
      cursor: wait;
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    @media(max-width: 769px) {
      overflow: scroll;
      width: 330px;
    }

    p {
      margin: 0;
    }

    table {
      background-color: #ffff;

      thead {
        background-color: #303c4c;
        color: #ffffff;
      }

      tbody {
        tr {
          border-bottom: #c4c5c7 1px solid;
          color: #303c4c;

          .editButton {
            color: #4F5D73;
            background: #FDE796;
            border: 2px solid #FDE796;
            border-radius: 9px;
          }

          .deleteButton {
            color: #FFFFFF;
            background: #EF4848;
            border: #EF4848;
            border-radius: 9px;
          }
        }

      }
    }
  }
}
